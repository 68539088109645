






























































































































































































































import { format } from 'date-fns';
import { Component, Vue } from 'vue-property-decorator';

import DataApiManager from '@/api/dataCoreApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';

import { DataCoreSvcFeedrunhdlGetRunOutput, FeedRunApi } from '../../../generated/data-core-svc';

@Component({
  components: {
    UiButton,
    UiLoading,
    UiPageHeader,
  },
})
export default class FeedRunDetail extends BaseVue {
  public feedRun: DataCoreSvcFeedrunhdlGetRunOutput | null = null;
  public isLoading = false;
  public error: string | null = null;

  get feedId(): string {
    return this.$route.params.feedId;
  }

  get feedRunId(): string {
    return this.$route.params.runId;
  }

  async mounted() {
    await this.loadFeedRunDetails();
  }

  goBackToFeed() {
    this.$router.push({ name: 'FeedDetailV2', params: { id: this.feedId } });
  }

  async loadFeedRunDetails() {
    this.isLoading = true;
    this.error = null;
    try {
      // Create a new instance of FeedRunApi using the same base URL as other APIs
      const defaultApi = DataApiManager.getInstance();
      const baseUrl = (defaultApi as any).basePath;
      const feedRunApi = new FeedRunApi(undefined, baseUrl);

      const resp = await feedRunApi.getRun(this.orgId, this.feedId, this.feedRunId, {
        withCredentials: true,
      });

      if (resp.status === 200) {
        this.feedRun = resp.data;
      } else {
        this.error = `Error loading feed run details: ${resp.statusText || 'Unknown error'}`;
      }
    } catch (err) {
      console.error('Error loading feed run details:', err);
      this.error = `Error loading feed run details: ${(err as Error).message || 'Unknown error'}`;
    } finally {
      this.isLoading = false;
    }
  }

  formatDate(dateString: string | undefined): string {
    if (!dateString) return 'N/A';
    try {
      return format(new Date(dateString), 'MMM d, yyyy h:mm:ss a');
    } catch (e) {
      return dateString;
    }
  }

  calculateDuration(startTime?: string, endTime?: string): string {
    if (!startTime) return 'N/A';

    const start = new Date(startTime);
    const end = endTime ? new Date(endTime) : new Date();

    try {
      // Calculate duration in milliseconds
      const durationMs = end.getTime() - start.getTime();

      // Convert to appropriate units
      const seconds = Math.floor(durationMs / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ${hours % 24} hr${hours % 24 !== 1 ? 's' : ''}`;
      } else if (hours > 0) {
        return `${hours} hr${hours !== 1 ? 's' : ''} ${minutes % 60} min${minutes % 60 !== 1 ? 's' : ''}`;
      } else if (minutes > 0) {
        return `${minutes} min${minutes !== 1 ? 's' : ''} ${seconds % 60} sec${seconds % 60 !== 1 ? 's' : ''}`;
      } else {
        return `${seconds} second${seconds !== 1 ? 's' : ''}`;
      }
    } catch (e) {
      return 'N/A';
    }
  }

  calculateProcessingRate(feedRun: DataCoreSvcFeedrunhdlGetRunOutput): string {
    if (!feedRun.startTime || !feedRun.processedItems) return '0';

    const start = new Date(feedRun.startTime);
    const end = feedRun.endTime ? new Date(feedRun.endTime) : new Date();

    // Calculate duration in minutes
    const durationMinutes = (end.getTime() - start.getTime()) / (1000 * 60);

    if (durationMinutes <= 0) return '0';

    // Calculate items per minute
    const rate = feedRun.processedItems / durationMinutes;

    return rate.toFixed(2);
  }

  getCurrentStepIndex(): number {
    if (!this.feedRun || !this.feedRun.stepNames || !this.feedRun.currentStepName) return -1;
    return this.feedRun.stepNames.indexOf(this.feedRun.currentStepName);
  }

  statusClass(status: string | undefined): Record<string, boolean> {
    if (!status) return { 'tw-text-gray-800': true };

    return {
      'tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-semibold tw-rounded-full': true,
      'tw-bg-green-100 tw-text-green-800': status === 'COMPLETED',
      'tw-bg-yellow-100 tw-text-yellow-800': status === 'RUNNING',
      'tw-bg-red-100 tw-text-red-800': status === 'FAILED',
      'tw-bg-blue-100 tw-text-blue-800': status === 'PENDING',
      'tw-bg-gray-100 tw-text-gray-800': ['COMPLETED', 'RUNNING', 'FAILED', 'PENDING'].indexOf(status) === -1,
    };
  }
}
